import React from 'react';
import BlogCard from '../components/blog-card';

const ArticleStack = ({ nodes, className }) => {
  const chunkedNodes = chunkArray(nodes, 5);

  return (
    <div
      className={
        'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 max-w-screen-xl mx-auto ' +
        className
      }
    >
      {chunkedNodes.map(nodes => {
        return nodes.map((node, index) => {
          const lgColSpan =
            index === 0 || index === 1 ? 'lg:col-span-3' : 'lg:col-span-2';

          return (
            <BlogCard
              className={'mr-3 mb-3 ' + lgColSpan}
              key={index}
              title={node.title}
              slug={node.slug}
              categoryName={node.category.name}
              categorySlug={node.category.slug}
              authorName={node.author.name}
              authorImage={node.author.picture.childImageSharp.fixed}
              authorSlug={node.author.profileSlug}
              image={node.image.childImageSharp.fluid}
              description={node.description}
              publishedDate={node.publishedAt}
            ></BlogCard>
          );
        });
      })}
    </div>
  );
};

export default ArticleStack;

function chunkArray(array, size) {
  let result = [];
  let arrayCopy = [...array];
  while (arrayCopy.length > 0) {
    result.push(arrayCopy.splice(0, size));
  }
  return result;
}
