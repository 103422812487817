import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

const ArticleCategories = ({ className, slug }) => {
  const data = useStaticQuery(graphql`
    query ArticleCategoriesQuery {
      allStrapiCategory {
        nodes {
          name
          slug
        }
      }
    }
  `);

  const currentSlug = slug;
  const everythingStyles =
    slug === 'articles' ? 'text-blue-200 font-medium' : 'text-blue-100';
  return (
    <ul className={'flex flex-wrap divide-x-2 divide-blue-700 ' + className}>
      <li
        className={
          'text-blue-100 small-caps pr-4 hover:underline ' + everythingStyles
        }
      >
        <Link to="/articles">Everything</Link>
      </li>
      {data.allStrapiCategory.nodes.map(({ name, slug }, index) => {
        const categoryStyles =
          currentSlug === slug ? 'text-blue-200 font-medium' : 'text-blue-100';
        return (
          <li
            className={
              'text-blue-100 small-caps px-4 hover:underline ' + categoryStyles
            }
            key={index}
          >
            <Link to={'/' + slug}>{name}</Link>
          </li>
        );
      })}
    </ul>
  );
};

export default ArticleCategories;
