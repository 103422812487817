import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ArticleStack from '../components/article-stack';
import ArticleCategories from '../components/article-categories';

export default function ArticlesByCategory({ data, pageContext }) {
  return (
    <Layout>
      <SEO title="Article Category" />

      <div className="blue-radial-background min-h-screen p-3 md:p-12 md:pr-9 pr-0 pt-16 md:pt-16">
        <div className="mx-auto max-w-screen-xl mb-12">
          <ArticleCategories className="mt-12" slug={pageContext.slug} />
          <div className="mt-12 h-1 bg-blue-700 mr-3 lg:mr-6 border-l-8 border-blue-200" />
          <h2 className="pt-4 text-blue-200 text-2xl">
            {data.strapiCategory.name}
          </h2>
          <h3 className="text-blue-100 text-lg pt-2 font-sans">
            {data.strapiCategory.description}
          </h3>
        </div>
        <ArticleStack nodes={data.allStrapiArticle.nodes} />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    allStrapiArticle(
      filter: { status: { eq: "published" }, category: { slug: { eq: $slug } } }
      sort: { order: DESC, fields: publishedAt }
    ) {
      nodes {
        title
        slug
        category {
          name
          slug
        }
        author {
          name
          picture {
            childImageSharp {
              fixed(quality: 90, width: 48, height: 48) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          profileSlug
        }
        image {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        description
        publishedAt(formatString: "MMMM Do, YYYY")
      }
    }
    strapiCategory(slug: { eq: $slug }) {
      description
      name
    }
  }
`;
